<template>
    <div  >
<van-nav-bar
  title="暗码设置"
  left-text="返回"
  left-arrow
  @click-left="onClickLeft"
>
	</van-nav-bar>
    <h1>暗码设置</h1>
    <van-row>
    <van-form >
        <van-cell-group inset>
            <van-field
                v-model="code"
                name="code"
                label="暗码设置"
                placeholder="暗码设置"
                :rules="[{ required: true, message: '请填写暗码' }]"
            />
        <!-- <van-button style="margin: 5px;" @click="toRoute('Setup')" type="primary" size="small" > 重新配置 </van-button> -->
        </van-cell-group>
        <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit" @click="getdata"  style="color: #fff;">
                提交
            </van-button>
        </div>
<!--        <div style="margin: 16px;position:absolute; bottom: 10%;right: 0;">
            <van-button style="margin: 5px;border-color: #fff; background-color: #fff;" @click="toRoute('AdminAnpwSetup')" type="primary" size="small" > 设置暗码 </van-button>
        </div>-->
    </van-form>
  </van-row>
  </div>
</template>
<script>
import { ref } from "vue";
import { Dialog } from 'vant';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import axios from 'axios'
const visitorIdd = ref('');
//获取设备唯一标识
const getUniqueCode = () => {
    FingerprintJS.load().then(fp => {
        fp.get().then(result => {
            visitorIdd.value = result.visitorId;
            console.log('获取设备唯一标识：',visitorIdd.value);
        });
    });
}
   export default {
        data() {
           return {
                code:"",
           } 
        },
        computed: {

        },
        mounted() {
            getUniqueCode()
            if(window.localStorage.getItem('is_admin') != 1){
                this.$router.push({path: '/wehchat/adminvalidate'})
            } 
           // this.getdata ()
          
        },
        methods: {
        onClickLeft () {
            this.$router.push({path: '/wehchat/adminal'})
        },
            getdata () {
        
            
            	if(!this.code) {
                          Dialog.confirm({
                            title: '',
                               message: '注册码不能为空',
                               confirmButtonText: '取消',
                               confirmButtonColor: '#1f517c',
                               cancelButtonText: '确定'
                           })
            		return;
            	}
            axios.post(this.$adminUrl+'/api/index/edit_wxpwd',{pwd:this.code,an:1,code:window.localStorage.getItem('root_code')})
            	.then((response) => {
            		//console.log('response',response)
            		if(response.data.code=='200'){
            			//sessionStorage.setItem('is_root', 1);
            	/*		window.localStorage.setItem('is_root', 1);
            			window.localStorage.setItem('root_code',this.code);*/
                          Dialog.confirm({
                            title: '',
                               message: "设置成功",
                               confirmButtonText: '确定',
                               confirmButtonColor: '#1f517c',
                               cancelButtonText: '取消'
                           })  .then(() => {
                              this.$router.push({path: '/wehchat/adminal'})
                              })
                              
                              			
            			
            	/*		router.push({
            				name: "Index",
            				query: {}
            			});*/
            		//	showSuccessToast("验证成功");
            			return;
            		}else{
            	//		window.localStorage.setItem('is_root', 0);
            			//showFailToast(rs.msg);
            			console.log(response.data.message)
                          Dialog.confirm({
                            title: '',
                               message: "服务升级中，请联系客服",
                               confirmButtonText: '取消',
                               confirmButtonColor: '#1f517c',
                               cancelButtonText: '确定'
                           })
            		//	showFailToast("服务升级中，请联系客服");
            			return;
            		}
            	}).catch(error => {
            		console.log('error',error)
            	//	window.localStorage.setItem('is_root', 0);
            	//	showFailToast("服务升级中，请联系客服");
            		//console.error('Error checking file existence:', error);
            	});   
            },  	
        }

   }
</script>

<style scoped>
    button{
		color: #fff;
	}
	body {
		 font-size: 14px;
		 font-weight: 700;
	}
	h1{
		margin-left: 6%;
		margin-top: 5%;
		margin-bottom: 10%;
		font-size: 16px;
		font-weight: bold;
	}
</style>